import Vue from "vue";
import VueRouter from "vue-router";
import Home from "../views/Home.vue";
import Signup from "../views/auth/Signup.vue";
import Login from "../views/auth/Login.vue";
import PasswordReset from "../views/auth/PasswordReset.vue";
// import store from '../store'
import firebase from "firebase/app";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Home",
    component: Home,
  },
  {
    path: "/signup",
    name: "Signup",
    component: Signup,
  },
  {
    path: "/login",
    name: "Login",
    component: Login,
  },
  {
    path: "/password-reset",
    name: "PasswordReset",
    component: PasswordReset,
  },
  {
    path: "/watch",
    name: "Watch",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/Watch.vue"),
  },
  {
    path: "/contact",
    name: "Contact",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/Contact.vue"),
  },
  {
    path: "/onboarding",
    name: "Onboarding",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "onboarding" */ "../views/Onboarding.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/dashboard",
    name: "Dashboard",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "dashboard" */ "../views/Dashboard.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/settings",
    name: "Settings",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "settings" */ "../views/Settings.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/speakers",
    name: "Speakers",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "speakers" */ "../views/Speakers.vue"),
  },
  {
    path: "/speakers/:slug",
    name: "SpeakerDetail",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(
        /* webpackChunkName: "SpeakerDetail" */ "../views/SpeakerDetail.vue"
      ),
  },
  {
    path: "/category/:id",
    name: "CategoryDetails",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(
        /* webpackChunkName: "SpeakerDetail" */ "../views/CategoryDetails.vue"
      ),
  },
  {
    path: "/dear_mama_admin",
    name: "AdminHome",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(
        /* webpackChunkName: "AddSpeakerVideo" */ "../views/admin/AdminHome.vue"
      ),
    meta: {
      requiresAuth: true,
      requiresAdmin: true,
    },
  },
  {
    path: "/dear_mama_admin/users",
    name: "AddAdmin",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(
        /* webpackChunkName: "AddSpeakerVideo" */ "../views/admin/Users.vue"
      ),
    meta: {
      requiresAuth: true,
      requiresAdmin: true,
    },
  },
  {
    path: "/dear_mama_admin/add_admin",
    name: "AddAdmin",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(
        /* webpackChunkName: "AddSpeakerVideo" */ "../views/admin/AddAdmin.vue"
      ),
    meta: {
      requiresAuth: true,
      requiresAdmin: true,
    },
  },
  {
    path: "/dear_mama_admin/add_speaker",
    name: "AddSpeaker",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(
        /* webpackChunkName: "AddSpeakerVideo" */ "../views/admin/AddSpeaker.vue"
      ),
    meta: {
      requiresAuth: true,
      requiresAdmin: true,
    },
  },
  {
    path: "/dear_mama_admin/add_speaker_video",
    name: "AddSpeakerVideo",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(
        /* webpackChunkName: "AddSpeakerVideo" */ "../views/admin/AddSpeakerVideo.vue"
      ),
    meta: {
      requiresAuth: true,
      requiresAdmin: true,
    },
  },
  {
    path: "/dear_mama_admin/edit_speaker/:slug",
    name: "EditSpeaker",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(
        /* webpackChunkName: "AddSpeakerVideo" */ "../views/admin/EditSpeaker.vue"
      ),
    meta: {
      requiresAuth: true,
      requiresAdmin: true,
    },
  },
  {
    path: "/dear_mama_admin/edit_video/:id",
    name: "EditVideo",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(
        /* webpackChunkName: "AddSpeakerVideo" */ "../views/admin/EditVideo.vue"
      ),
    meta: {
      requiresAuth: true,
      requiresAdmin: true,
    },
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
  scrollBehavior: function(to) {
    if (to.hash) {
      return {
        selector: to.hash,
      };
    }
  },
});

// router guards
router.beforeEach((to, from, next) => {
  // check to see if route has auth guard
  if (to.matched.some((rec) => rec.meta.requiresAuth)) {
    // check auth state of user
    let user = firebase.auth().currentUser;
    if (user) {
      // User is signed in. Proceed to route
      next();
    } else {
      // No user is signed in. Save target path
      sessionStorage.setItem("redirectPath", to.path);
      // Redirect to signup
      next({
        name: "Signup",
      });
    }
  } else if (to.matched.some((rec) => rec.meta.requiresAdmin)) {
    // check custom claims auth of user
    firebase
      .auth()
      .currentUser.getIdTokenResult(true) // 1
      .then((idTokenResult) => {
        // 2 Confirm the user is an Admin
        if (idTokenResult.claims.admin) {
          // 3
          // Show admin UI.
          next();
        } else {
          // Show regular user UI.
          next({
            name: "Login",
          });
        }
      });
  } else {
    // if route is not guarded by auth, proceed
    next();
    window.scrollTo(0, 0);
  }
});

export default router;
