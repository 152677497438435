<template>
  <v-app class="app app-background">
    <NavBar />

    <v-main>
      <router-view></router-view>
    </v-main>

    <FooterSection />
  </v-app>
</template>

<script>
import NavBar from "./components/NavBar.vue";

export default {
  name: "App",

  components: {
    NavBar,
    FooterSection: () => import("@/components/landing/FooterSection"),
  },
};
</script>


<style>
.rubikFont {
  font-family: "Mansalva", cursive !important;
}
.app-background {
  background-color: #000000 !important;
}
</style>
