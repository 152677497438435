<template>
  <div>
    <v-app-bar app color="rgba(0,0,0,0.75)" dark>
      <v-app-bar-nav-icon
        class="hidden-md-and-up blackish--text"
        @click="drawer = !drawer"
      />

      <v-container class="mx-auto py-0">
        <v-row align="center">
          <v-spacer></v-spacer>

          <v-btn
            text
            color="white"
            v-for="item in menuItems"
            :key="item.title"
            class="hidden-sm-and-down"
            :to="item.link"
          >
            {{ item.title }}
          </v-btn>

          <v-btn
            text
            color="secondary"
            v-if="user && user.ua"
            to="/dear_mama_admin"
            small
          >
            ADMIN
          </v-btn>

          <InAppNotifications v-if="user" style="z-index: 99" />

          <v-spacer></v-spacer>
        </v-row>
      </v-container>
    </v-app-bar>

    <v-navigation-drawer
      v-model="drawer"
      class="black"
      style="position: fixed; top: 0; left: 0; overflow-y: scroll"
      temporary
    >
      <v-card class="d-flex justify-start my-10 px-3" color="black" flat>
        <v-img
          alt="Vuetify Name"
          class="shrink"
          contain
          min-width="100"
          max-width="300"
          :src="require('@/assets/logo_dear_mama.png')"
        />
      </v-card>
      <v-list nav>
        <v-list-item-group active-class="white--text text--accent-4">
          <v-list-item
            v-for="item in menuItems"
            :key="item.title"
            :to="item.link"
          >
            <v-list-item-title class="white--text">{{
              item.title
            }}</v-list-item-title>
          </v-list-item>
        </v-list-item-group>
      </v-list>
    </v-navigation-drawer>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "NavBar",

  components: {
    InAppNotifications: () => import("@/components/utility/InAppNotifications"),
  },

  data: () => ({
    drawer: false,
  }),

  computed: {
    // map `this.user` to `this.$store.getters.user`
    ...mapGetters({
      user: "user",
      ps: "ps",
    }),
    menuItems() {
      // let menuItems = [
      //   { icon: "mdi-account", title: "Accedi", link: "/login" },
      //   { icon: "mdi-account", title: "Registrati", link: "/signup" },
      // ];
      let menuItems = [
        { title: "About", link: "/" },
        {
          title: "Special content",
          link: "/speakers",
        },
        { title: "Watch", link: "/watch" },
        { title: "Contact", link: "/contact" },
      ];
      if (this.$store.getters.user) {
        menuItems = [
          { title: "About", link: "/" },
          {
            title: "Special content",
            link: "/speakers",
          },
          { title: "Watch", link: "/watch" },
          { title: "Contact", link: "/contact" },
          { title: "Settings", link: "/settings" },
        ];
      }
      return menuItems;
    },
  },
};
</script>

<style></style>
