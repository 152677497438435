import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify';
import { auth } from '@/plugins/firebase.js'

import titleMixin from './components/mixins/titleMixin'
Vue.mixin(titleMixin)


import VueGtag from "vue-gtag";
Vue.use(
  VueGtag,
  {
    config: { id: process.env.VUE_APP_MEASUREMENT_ID },
  },
  router
);

import VueCoreVideoPlayer from 'vue-core-video-player'
Vue.use(VueCoreVideoPlayer)

import Datetime from 'vue-datetime'
import 'vue-datetime/dist/vue-datetime.css'
Vue.use(Datetime)

import VueFilterDateFormat from '@vuejs-community/vue-filter-date-format';
Vue.use(VueFilterDateFormat);

import VueChatScroll from 'vue-chat-scroll'
Vue.use(VueChatScroll)

Vue.config.productionTip = false





let app
auth.onAuthStateChanged(async (user) => {

  if (!app) {
    app = new Vue({
      router,
      store,
      vuetify,
      render: h => h(App)
    }).$mount('#app')
  }

  if (user) {
    await store.dispatch('fetchUserProfile', user),
    await store.dispatch("setAnalyticsUser");
    await store.dispatch("fetchPaymentStatus", user);
    await store.dispatch("fetchNotifications", user);
  }

  store.dispatch('fetchSpeakers')
  store.dispatch("fetchPrices");

})