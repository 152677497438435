import firebase from "firebase/app";

// Import needed firebase modules
import "firebase/auth";
import "firebase/firestore";
import "firebase/functions";
import "firebase/storage"

// Firebase app config
const config = {
  apiKey: process.env.VUE_APP_API_KEY,
  authDomain: process.env.VUE_APP_AUTH_DOMAIN,
  databaseURL: process.env.VUE_APP_DATABASE_URL,
  projectId: process.env.VUE_APP_PROJECT_ID,
  storageBucket: process.env.VUE_APP_STORAGE_BUCKET,
  messagingSenderId: process.env.VUE_APP_MESSAGING_SENDER_ID,
  appId: process.env.VUE_APP_APP_ID
};

// Init our firebase app
firebase.initializeApp(config);

// utils
const fb = firebase
const db = firebase.firestore()
const auth = firebase.auth()
const fbfunction = firebase.functions()
const storage = firebase.storage()

// collection references
const usersCollection = db.collection('users')
const speakersCollection = db.collection('speakers')
const videosCollection = db.collection('videos')
const questionsCollection = db.collection('questions')
const productsCollection = db.collection("products");
const emailsCollection = db.collection("emails");
const eventsCollection = db.collection("events");

// export utils/refs
export {
  fb,
  db,
  auth,
  fbfunction,
  storage,
  usersCollection,
  speakersCollection,
  videosCollection,
  questionsCollection,
  productsCollection,
  emailsCollection,
  eventsCollection
}