<template>
  <v-container class="login fill-height" align="center" justify="center" fluid>
    <v-row align="center" justify="center">
      <v-col cols="12" sm="8" md="4">
        <v-card class="elevation-12">
          <v-toolbar color="primary" dark flat>
            <v-toolbar-title>Reimposta Password</v-toolbar-title>
            <v-spacer />
          </v-toolbar>
          <v-card-text>
            <v-form>
              <v-text-field
                label="Email"
                name="email"
                prepend-icon="mdi-email"
                type="email"
                v-model="email"
              />
            </v-form>
          </v-card-text>
          <v-card-actions class="px-3">
            <v-btn color="primary white--text" block large @click="resetPassword">Reimposta Password</v-btn>
          </v-card-actions>
          <v-card-text v-if="feedback" class="red--text text-center">{{feedback}}</v-card-text>
          <v-card-text class="text-center">
            <router-link class="body-2" :to="{ name: 'Login' }">Hai già un account? Accedi subito!</router-link>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
    <v-row align="center" justify="center" class="mt-5">
      <p class="text-center body-2">
        Non hai un account?
        <span>
          <router-link :to="{ name: 'Signup' }">Creane uno subito!</router-link>
        </span>
      </p>
    </v-row>
  </v-container>

  <!-- Exploration of two column layout
  <v-container class="grey lighten-5 fill-height">
    <v-row class="mb-6" align="center" no-gutters>
      <v-col>
        <v-card outlined>A</v-card>
      </v-col>
      <v-col align="center">
        <v-card outlined>B</v-card>
      </v-col>
    </v-row>
  </v-container>-->
</template>

<script>
import firebase from "firebase/app";

// Import needed firebase modules
import "firebase/auth";
import "firebase/firestore";

export default {
  name: "Password Reset",
  title: "Password Reset",

  data() {
    return {
      email: null,
      feedback: null
    };
  },
  methods: {
    resetPassword() {
      if (this.email) {
        firebase
          .auth()
          .sendPasswordResetEmail(this.email)
          .then(() => {
            this.$router.push({ name: "Login" });
          })
          .catch(error => {
            var errorCode = error.code;
            var errorMessage = error.message;
            console.log(`error code is`, errorCode);
            console.log(`error message is`, errorMessage);
            this.feedback = error.message;
          });
      } else {
        this.feedback = "Please fill email and try again";
      }
    }
  }
};
</script>

<style scoped>
</style>