<template>
  <div>
    <v-card class="d-flex justify-center mt-8 mb-6" color="black" flat>
      <v-img
        alt="Vuetify Name"
        class="shrink"
        contain
        min-width="100"
        max-width="350"
        :src="require('@/assets/logo_dear_mama.png')"
      />
    </v-card>
    <HeroSection />
    <TrailerVideo />
    <AboutSection />
    <SpeakersSection />
    <ArticleSection />
    <PartnersSection />
    <SubscribeMailchimp />
  </div>
</template>

<script>
// @ is an alias to /src
import { mapGetters } from "vuex";

export default {
  name: "Home",
  title: "Home",

  components: {
    HeroSection: () => import("@/components/landing/HeroSection"),
    AboutSection: () => import("@/components/landing/AboutSection"),
    ArticleSection: () => import("@/components/landing/ArticleSection"),
    SpeakersSection: () => import("@/components/landing/SpeakersSection"),
    PartnersSection: () => import("@/components/landing/PartnersSection"),
    SubscribeMailchimp: () => import("@/components/misc/SubscribeMailchimp"),
    TrailerVideo: () => import("@/components/landing/TrailerVideo"),
  },
  data() {
    return {};
  },
  computed: {
    // map `this.user` to `this.$store.getters.user`
    ...mapGetters({
      user: "user",
    }),
  },
  mounted() {
    console.log(process.env.VUE_APP_PROJECT_ID);
  },
};
</script>
