import Vue from "vue";
import Vuex from "vuex";
import {
  fb,
  auth,
  usersCollection,
  speakersCollection,
  videosCollection,
  productsCollection,
  eventsCollection,
  storage,
} from "@/plugins/firebase.js";
import router from "../router/index";
// import createPersistedState from "vuex-persistedstate";

Vue.use(Vuex);
const store = new Vuex.Store({
  state: {
    user: null,
    ps: null, // payment status
    prices: [],
    notifications: [],
    loadedSpeakers: [],
    // allVideos: [],
    singleSpeakerVideos: [],
  },
  mutations: {
    setUser(state, payload) {
      state.user = payload;
    },
    setPaymentStatus(state, payload) {
      state.ps = payload;
    },
    setPrices(state, payload) {
      state.prices = payload;
    },
    setNotifications(state, payload) {
      state.notifications = payload;
    },
    setLoadedSpeakers(state, payload) {
      state.loadedSpeakers = payload;
    },
    // setAllVideos(state, payload) {
    //   state.allVideos = payload
    // },
    setSingleSpeakerVideos(state, payload) {
      state.singleSpeakerVideos = payload;
    },
  },
  actions: {
    async signUserUp({ dispatch }, payload) {
      // sign user up with firebase auth
      const { user } = await auth.createUserWithEmailAndPassword(
        payload.email,
        payload.password
      );

      // update firebase auth user profile with user name
      await auth.currentUser.updateProfile({
        displayName: payload.name,
      });

      // const delay = ms => new Promise(res => setTimeout(res, ms));
      // console.log('starting to wait')
      // await delay(10000); // wait 6 seconds
      // console.log('done waiting')

      // create user profile object in userCollections
      await usersCollection.doc(user.uid).set(
        {
          email: user.email,
          id: user.uid,
          name: payload.name,
          created_at: fb.firestore.FieldValue.serverTimestamp(),
        },
        { merge: true }
      );

      // set user profile to GA4
      Vue.$gtag.query("set", "user_properties", {
        user_id: user.uid,
        user_uid: user.uid,
      });

      // GA4 event: user signup
      Vue.$gtag.query("event", "sign_up", {
        method: "email",
      });

      // fetch user profile and set in state
      await dispatch("fetchUserProfile", user);
    },

    async signUserIn({ dispatch }, payload) {
      // log in user with firebase auth
      const { user } = await auth.signInWithEmailAndPassword(
        payload.email,
        payload.password
      );

      // fetch user profile from firestore db and set in state
      await dispatch("fetchUserProfile", user);

      // set user profile to GA4
      Vue.$gtag.query("set", "user_properties", {
        user_id: user.uid,
        user_uid: user.uid,
      });

      // GA4 event: user login
      Vue.$gtag.query("event", "login", {
        method: "email",
      });

      console.log(sessionStorage.getItem("redirectPath"));

      //Go to '/defaultpath' if no redirectPath value is set
      router.push(sessionStorage.getItem("redirectPath") || "/speakers");
      //Cleanup redirectPath
      sessionStorage.removeItem("redirectPath");
    },

    async logUserOut({ commit }) {
      // GA4 event: user log out
      Vue.$gtag.query("event", "log_out");

      // log out user with firebase auth
      await auth.signOut();

      // clear vuex state and redirect user
      commit("setUser", {});
      router.push("/login");
    },

    async fetchUserProfile({ commit }, user) {
      // fetch user profile
      const userProfile = await usersCollection.doc(user.uid).get();

      const profileDuplicated = { ...userProfile.data() };

      // check if admin
      let isUserAdmin = false;
      await fb
        .auth()
        .currentUser.getIdTokenResult(true) // 1
        .then((idTokenResult) => {
          // 2 Confirm the user is an Admin
          if (idTokenResult.claims.admin) {
            // 3
            isUserAdmin = true;
          }
        });
      profileDuplicated.ua = isUserAdmin; // ua = user admin

      // check gaClientId
      if (!profileDuplicated.gaClientId) {
        console.log("there is not gaClientId. going to take it");

        // Vue.$gtag.getAll().forEach( (tracker) => {
        //   var id = tracker.get('clientId'); console.log('id is --> ' + id)
        // })

        // Vue.$gtag('get', 'G-PE4BN46N0E', 'client_id', (clientid) => {
        //   console.log(clientid)
        // })
      }

      // fetch active subscription (if any)
      const userActiveSubscriptions = [];
      await usersCollection
        .doc(user.uid)
        .collection("subscriptions")
        .where("status", "==", "active")
        .onSnapshot(async (snapshot) => {
          // In this implementation we only expect one active Subscription to exist
          // return snapshot.docs[0];
          if (snapshot.docs[0]) {
            userActiveSubscriptions.push(snapshot.docs[0].data());
          } else {
            console.log("user does not have an active subscription");
          }
        });
      profileDuplicated.subsc = userActiveSubscriptions;

      // get the user public avatar from storage -- removed for now as not really
      // let pathAvatarImage =
      //   "gs://" +
      //   process.env.VUE_APP_STORAGE_BUCKET +
      //   "/" +
      //   "users/" +
      //   user.uid +
      //   "/avatar-original";
      // const urlAvatar = await storage.refFromURL(pathAvatarImage).getDownloadURL();
      // profileDuplicated.avatar = urlAvatar

      // set user profile in state
      commit("setUser", profileDuplicated);
    },

    async setAnalyticsUser({ getters }) {
      let user = getters.user;

      // set user profile to GA4
      Vue.$gtag.query("set", "user_properties", {
        user_id: user.uid,
        user_uid: user.uid,
      });
    },

    async fetchPaymentStatus({ commit }, user) {
      const userActiveSubscriptions = [];

      // fetch active subscription (if any) from db (stripe data added via the stripe/firebase integration)
      await usersCollection
        .doc(user.uid)
        .collection("subscriptions")
        .where("status", "in", ["trialing", "active"])
        .onSnapshot(async (snapshot) => {
          // In this implementation we only expect one active Subscription to exist
          const doc = snapshot.docs[0];
          if (doc) {
            // console.log(doc.id, ' => status ', doc.data().status,' => data ', doc.data());
            let singlesubs = doc.data();
            userActiveSubscriptions.push(singlesubs);
          } else {
            // console.log("user does not have an active subscription");
          }
        });

      // console.log('userActiveSubscriptions =>', userActiveSubscriptions)

      // set user profile in state
      commit("setPaymentStatus", userActiveSubscriptions);
    },

    async fetchPrices({ commit }) {
      const prices = [];

      await productsCollection
        .where("active", "==", true)
        .get()
        .then(function(querySnapshot) {
          querySnapshot.forEach(async function(doc) {
            // console.log( "Price ", doc.id, " => ", doc.data());
            const priceSnap = await doc.ref.collection("prices").get();
            priceSnap.docs.forEach((doc) => {
              let singleprice = doc.data();
              singleprice.id = doc.id;
              if (singleprice.interval == "month") {
                singleprice.intervalTitle = "mensile";
              } else {
                singleprice.intervalTitle = "annuale";
              }
              prices.push(singleprice);
            });
          });
        })
        .catch((err) => console.log(err));

      commit("setPrices", prices);
    },

    async fetchNotifications({ getters }) {
      let user = getters.user;

      eventsCollection
        .where("target_user", "==", user.id)
        .orderBy("created_at", "desc")
        .onSnapshot((snapshot) => {
          let notifs = [];

          snapshot.forEach((doc) => {
            let item = doc.data();
            item.id = doc.id;

            notifs.push(item);
          });
          store.commit("setNotifications", notifs);
        });
    },

    fetchSpeakers({ commit }) {
      // fetch user profile
      speakersCollection
        .get()
        .then((result) => {
          const speakers = [];
          if (result.empty) {
            console.log("No matching documents.");
            return;
          } else {
            result.forEach(async (doc) => {
              let singleSpeaker = doc.data();
              singleSpeaker.id = doc.id;

              // Fetch image download url from storage
              let pathSpeakerImage =
                "gs://" +
                process.env.VUE_APP_STORAGE_BUCKET +
                "/" +
                "speakers/" +
                singleSpeaker.id +
                "/speaker_image-original";
              const urlImage = await storage
                .refFromURL(pathSpeakerImage)
                .getDownloadURL();

              singleSpeaker.downloadImgUrl = urlImage;
              speakers.push(singleSpeaker);
            });
          }
          commit("setLoadedSpeakers", speakers);
        })
        .catch((err) => {
          console.log(err);
        });
    },

    // fetchVideos({ commit }) {
    //   // fetch user profile
    //   videosCollection.get()
    //     .then(result => {
    //       const videoss = []
    //       if (result.empty) {
    //         console.log("No matching documents.");
    //         return;
    //       } else {
    //         result.forEach(async (doc) => {
    //           let singleVideo = doc.data();
    //           singleVideo.id = doc.id;

    //           // Fetch video download url from storage
    //           let pathSigleVideo = "gs://" + process.env.VUE_APP_STORAGE_BUCKET + "/" + "speakers/" + singleVideo.main_speakerId + "/" + singleVideo.id;
    //           const urlVideo = await storage
    //             .refFromURL(pathSigleVideo)
    //             .getDownloadURL()
    //           singleVideo.downloadVideoUrl = urlVideo

    //           // Fetch video download url from storage
    //           let pathSigleVideoCover = "gs://" + process.env.VUE_APP_STORAGE_BUCKET + "/" + "speakers/" + singleVideo.main_speakerId + "/" + singleVideo.id + "_cover";
    //           const urlVideoCover = await storage
    //             .refFromURL(pathSigleVideoCover)
    //             .getDownloadURL()
    //           singleVideo.downloadVideoCoverUrl = urlVideoCover

    //           videoss.push(singleVideo);
    //         });
    //       }
    //       commit('setAllVideos', videoss)
    //     })
    //     .catch(err => {
    //       console.log(err);
    //     });
    // },

    // fetchSingleSpeakerVideos({ commit }, speakerData) {

    //   // fetch user profile
    //   videosCollection.where("main_speaker", "==", speakerData.slug)
    //     .get()
    //     .then(result => {
    //       const singleSpeakerVideoss = []
    //       if (result.empty) {
    //         console.log("No matching documents.");
    //         return;
    //       } else {
    //         result.forEach(async (doc) => {
    //           let singleVideo = doc.data();
    //           singleVideo.id = doc.id;

    //           // Fetch cover download url from storage
    //           let pathSigleVideoCover = "gs://techsoup-together-frontend-dev.appspot.com/speakers/" + singleVideo.main_speakerId + "/" + singleVideo.id + "_cover";
    //           const urlVideoCover = await storage
    //             .refFromURL(pathSigleVideoCover)
    //             .getDownloadURL()
    //           singleVideo.downloadVideoCoverUrl = urlVideoCover

    //           // Fetch video download url from storage
    //           let pathSigleVideo = "gs://techsoup-together-frontend-dev.appspot.com/speakers/" + singleVideo.main_speakerId + "/" + singleVideo.id;
    //           const urlVideo = await storage
    //             .refFromURL(pathSigleVideo)
    //             .getDownloadURL()
    //           singleVideo.downloadVideoUrl = urlVideo

    //           singleSpeakerVideoss.push(singleVideo);
    //         });
    //       }
    //       commit('setSingleSpeakerVideos', singleSpeakerVideoss)
    //     })
    //     .catch(err => {
    //       console.log(err);
    //     });
    // }

    async fetchSingleSpeakerVideos({ commit }, speakerData) {
      const videoresults = await videosCollection
        .where("main_speaker", "==", speakerData.slug)
        .orderBy("created_at")
        .get();

      const singleSpeakerVideoss = [];

      await videoresults.forEach(async (doc) => {
        let singleVideo = doc.data();
        singleVideo.id = doc.id;

        // console.log(singleVideo.datetime);

        // Fetch cover download url from storage
        // let pathSigleVideoCover = "gs://" + process.env.VUE_APP_STORAGE_BUCKET + "/" + "speakers/" + singleVideo.main_speakerId + "/" + singleVideo.id + "_cover";
        // const urlVideoCover = await storage
        //   .refFromURL(pathSigleVideoCover)
        //   .getDownloadURL()
        // singleVideo.downloadVideoCoverUrl = urlVideoCover

        // Fetch video download url from storage
        let pathSigleVideo =
          "gs://" +
          process.env.VUE_APP_STORAGE_BUCKET +
          "/" +
          "speakers/" +
          singleVideo.main_speakerId +
          "/" +
          singleVideo.id;
        const urlVideo = await storage
          .refFromURL(pathSigleVideo)
          .getDownloadURL();
        singleVideo.downloadVideoUrl = urlVideo;

        // singleVideo.downloadVideoUrl = singleVideo.videoSource[1].src

        singleSpeakerVideoss.push(singleVideo);
      });

      commit("setSingleSpeakerVideos", singleSpeakerVideoss);
    },
  },

  getters: {
    user(state) {
      return state.user;
    },
    ps(state) {
      return state.ps;
    },
    prices(state) {
      return state.prices;
    },
    notifications(state) {
      return state.notifications;
    },
    loadedSpeakers(state) {
      return state.loadedSpeakers;
    },
    singleSpeaker(state) {
      return (slug) => {
        return state.loadedSpeakers.find((speaker) => {
          return speaker.slug === slug;
        });
      };
    },

    singleSpeakerVideos: (state) => {
      // const sortedVideos = state.singleSpeakerVideos.sort(compare)
      let videosSpeaker = state.singleSpeakerVideos;
      let sortedVideos = videosSpeaker.sort(
        (a, b) => a.videoPositionIndex - b.videoPositionIndex
      );

      return sortedVideos;

      // PREVIOUS LOGIC TO RETURN VIDEOS BASED ON THE USER (IF SIGNED UP, IF PAID). MODIFIED FOR DEAR MAMA
      // if (state.user && state.user.subsc[0]) {
      //   // console.log('user exists and is paid, returning all videos')
      //   return sortedVideos
      // } else if (state.user && !state.user.subsc[0]) {
      //   // console.log('user exists but it is not paid, returning only free videos')
      //   return sortedVideos.map(id => {
      //     if (id && id.paid_only) {
      //       const singlevideo = { ...id }
      //       singlevideo.src_video = null
      //       singlevideo.downloadVideoUrl = null
      //       singlevideo.blocked = true
      //       return singlevideo
      //     } else {
      //       return id
      //     }
      //   })
      // } else {
      //   // console.log('user is not authenticated')
      //   // WAS THIS FOR TS TOGETHER
      //   return sortedVideos.map(id => {
      //     if (id && id.paid_only) {
      //       const singlevideo = { ...id }
      //       singlevideo.src_video = null
      //       singlevideo.downloadVideoUrl = null
      //       singlevideo.blocked = true
      //       return singlevideo
      //     } else {
      //       return id
      //     }
      //   })
      //   // WAS THIS FOR TS TOGETHER
      //   // return sortedVideos.map(id => {
      //   //   if (id && id.paid_only) {
      //   //     const singlevideo = { ...id }
      //   //     singlevideo.src_video = null
      //   //     singlevideo.downloadVideoUrl = null
      //   //     return singlevideo
      //   //   } else if (id && !id.isTrailer) {
      //   //     const singlevideo1 = { ...id }
      //   //     singlevideo1.src_video = null
      //   //     singlevideo1.downloadVideoUrl = null
      //   //     return singlevideo1
      //   //   } else {
      //   //     return id
      //   //   }
      //   // })
      // }
    },
  },

  modules: {},

  // plugins: [createPersistedState()]
});

export default store;
