<template>
  <v-container class="fill-height" align="center" justify="center" fluid>
    <v-row align="center" justify="center">
      <v-col cols="12" sm="8" md="4">
        <v-card class="elevation-12">
          <v-toolbar color="primary" dark flat>
            <v-toolbar-title>Registrati</v-toolbar-title>
            <v-spacer />
          </v-toolbar>
          <v-card-text>
            <v-form v-model="valid" lazy-validation>
              <v-text-field
                label="Nome"
                name="fullname"
                prepend-icon="mdi-account"
                type="text"
                :rules="nameRules"
                v-model="name"
                required
              />

              <v-text-field
                label="Email"
                name="email"
                prepend-icon="mdi-email"
                type="email"
                :rules="emailRules"
                v-model="email"
              />

              <v-text-field
                id="password"
                label="Password"
                name="password"
                prepend-icon="mdi-lock"
                type="password"
                v-model="password"
                :rules="passwordRules"
                v-on:keyup.enter="onSignup"
              />
            </v-form>
          </v-card-text>
          <v-card-actions class="px-3">
            <v-btn color="primary white--text" block large @click="onSignup">Crea Account</v-btn>
          </v-card-actions>
          <v-card-text v-if="feedback" class="red--text text-center">{{feedback}}</v-card-text>
        </v-card>
      </v-col>
    </v-row>
    <v-row align="center" justify="center" class="mt-5">
      <p class="text-center body-2">
        Hai già un account?
        <span>
          <router-link :to="{ name: 'Login' }">Accedi</router-link>
        </span>
      </p>
    </v-row>

    <OverlayLoading
      :overlaymessage="overlaymessage"
      :overlaymessage2="overlaymessage2"
      :inProgress="inProgress"
    />
  </v-container>
</template>

<script>
export default {
  name: "Signup",
  title: "Signup",

  components: {
    OverlayLoading: () => import("@/components/OverlayLoading")
  },

  data() {
    return {
      valid: false,
      name: null,      
      nameRules: [
        v => !!v || 'Campo obbligatorio',
        v => (v && v.length <= 20) || 'Il nome deve essere al massimo di 20 caratteri',
      ],
      email: null,
      emailRules: [
        v => !!v || 'Campo obbligatorio',
        v => /.+@.+\..+/.test(v) || 'Inserisci una email valida',
      ],
      password: null,
      passwordRules: [
        v => !!v || 'Campo obbligatorio',
        v => (v && v.length >= 6) || 'La password deve essere di almeno 6 caratteri',
      ],
      feedback: null,

      inProgress: false,
      overlaymessage:
        "Sto creando account ed impostando credenziali. Attendi un attimo.",
      overlaymessage2: "L'intera operazione impiega circa 3-5 secondi."
    };
  },
  computed: {
    user() {
      return this.$store.getters.user;
    }
  },
  watch: {
    user(value) {
      if (value !== null && value !== undefined) {
        this.$router.push("/onboarding");
      }
    }
  },
  methods: {
    onSignup() {
      if(!this.name || !this.email || !this.password){
        this.feedback = "Aggiungi tutte le informazioni e prova di nuovo"
      } else { 
        this.inProgress = true;
        this.$store.dispatch("signUserUp", {
          email: this.email,
          password: this.password,
          name: this.name
        })
        .catch((err) => {
        this.inProgress = false;
        this.feedback = err.message
        })
      }
    }
  }
};
</script>

<style>
</style>







