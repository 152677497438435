<template>
  <v-container class="login fill-height" align="center" justify="center" fluid>
    <v-row align="center" justify="center">
      <v-col cols="12" sm="8" md="4">
        <v-card class="elevation-12">
          <v-toolbar color="primary" dark flat>
            <v-toolbar-title>Accedi</v-toolbar-title>
            <v-spacer />
          </v-toolbar>
          <v-card-text>
            <v-form>
              <v-text-field
                label="Email"
                name="email"
                prepend-icon="mdi-email"
                type="email"
                v-model="email"
              />

              <v-text-field
                id="password"
                label="Password"
                name="password"
                prepend-icon="mdi-lock"
                type="password"
                v-model="password"
              />
            </v-form>
          </v-card-text>
          <v-card-actions class="px-3">
            <v-btn color="primary white--text" block large @click="onSignin">Login</v-btn>
          </v-card-actions>
          <v-card-text v-if="feedback" class="red--text text-center">{{feedback}}</v-card-text>
          <v-card-text class="text-center">
            <router-link class="body-2" :to="{ name: 'PasswordReset' }">Password dimenticata?</router-link>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
    <v-row align="center" justify="center" class="mt-5">
      <p class="text-center body-2">
        Non hai un account?
        <span>
          <router-link :to="{ name: 'Signup' }">Creane uno subito!</router-link>
        </span>
      </p>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: "Login",
  title: "Login",

  data() {
    return {
      email: null,
      password: null,
      feedback: null
    };
  },
  computed: {
    user() {
      return this.$store.getters.user;
    }
  },
  // watch: {
  //   user(value) {
  //     if (value !== null && value !== undefined) {
  //       this.$router.push("/settings");
  //     }
  //   }
  // },
  methods: {
    onSignin() {
      this.$store.dispatch("signUserIn", {
        email: this.email,
        password: this.password
      })
      .catch((err) => {
        this.feedback = err.message
      })
    }
  }
};
</script>

<style scoped>
</style>